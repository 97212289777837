.main-page-holder {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  background-color: #e5ffe4;
}

.login-container {
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 18px;
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.356);
}

.input-fields-container {
  height: 45%;
  margin-top: 35px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.input-field .MuiOutlinedInput-root {
  border-radius: 10px;
}

.login-button {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  padding: 0 35px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.116);
  font-family: "Roboto";
  font-weight: 700;
  font-size: 140%;
  width: 100%;
  height: 65px;
  transition: 600ms;
}

.login-button.active {
  background: rgba(23, 198, 0, 0.7);
  color: black;
}

.login-button.inactive {
  background-color: rgba(157, 157, 157, 0.267);
  color: rgba(255, 255, 255, 0.62);
}

.logo-login-page {
  height: 40%;
  color: black;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo-image {
  width: 180px;
}
